<template>
  <b-overlay :show="loading" variant="white" :opacity="1">
    <b-card :title="$t('Schedules')">
      <!-- ARRIVAL -->
      <b-row>
        <b-col cols="12" class="mb-2">
          <div class="d-flex">
            <feather-icon icon="ArrowDownRightIcon" size="20" class="mr-2" />
            <h5>{{ $t("Arrival") }}</h5>
          </div>
        </b-col>
        <b-col v-if="hasArrivalInformation" cols="12">
          <b-row class="no-gutters mb-4">
            <gray-box-item
              v-if="accommodationArrivalTimeText"
              :label="$t('Accommodation arrival')"
              :text="accommodationArrivalTimeText"
            />
            <gray-box-item
              v-if="arrivalTransportText"
              :label="$t('Transport')"
              :text="arrivalTransportText"
            />
            <gray-box-item
              v-if="arrivalTransportLocalizatorText"
              :label="$t('Localizator')"
              :text="arrivalTransportLocalizatorText"
              :link="arrivalTransportLocalizatorLink"
            />
            <gray-box-item
              v-if="transportArrivalTimeText"
              :label="$t('Arrival time')"
              :text="transportArrivalTimeText"
            />
          </b-row>
        </b-col>
        <b-col v-else class="mb-2">
          <b-alert variant="warning" :show="true">
            <div class="alert-body font-weight-normal">
              {{ $t("pages.booking.schedules.noArrivalInformation") }}
            </div>
          </b-alert>
        </b-col>
      </b-row>

      <!-- DEPARTURE -->
      <b-row>
        <b-col cols="12" class="mb-2">
          <div class="d-flex">
            <feather-icon icon="ArrowUpLeftIcon" size="20" class="mr-2" />
            <h5>{{ $t("Departure") }}</h5>
          </div>
        </b-col>
        <b-col v-if="hasDepartureInformation" cols="12">
          <b-row class="no-gutters">
            <gray-box-item
              v-if="accommodationDepartureTimeText"
              :label="$t('Accommodation departure')"
              :text="accommodationDepartureTimeText"
            />
            <gray-box-item
              v-if="departureTransportText"
              :label="$t('Transport')"
              :text="departureTransportText"
            />
            <gray-box-item
              v-if="departureTransportLocalizatorText"
              :label="$t('Localizator')"
              :text="departureTransportLocalizatorText"
              :link="departureTransportLocalizatorLink"
            />
            <gray-box-item
              v-if="transportDepartureTimeText"
              :label="$t('Departure time')"
              :text="transportDepartureTimeText"
            />
          </b-row>
        </b-col>
        <b-col v-else>
          <b-alert variant="warning" :show="true">
            <div class="alert-body font-weight-normal">
              {{ $t("pages.booking.schedules.noDepartureInformation") }}
            </div>
          </b-alert>
        </b-col>
      </b-row>
    </b-card>
  </b-overlay>
</template>

<script>
import { BCard, BRow, BCol, BOverlay, BAlert } from "bootstrap-vue";
import { getTransportName } from "@methods";
import GrayBoxItem from "@/views/bookings/booking/components/GrayBoxItem.vue";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BOverlay,
    GrayBoxItem,
    BAlert,
  },
  computed: {
    loading() {
      return this.$store.getters["booking/loading"];
    },
    details() {
      return this.$store.getters["booking/details"];
    },
    accommodationArrivalTimeText() {
      return this.details?.accommodationArrivalTime
        ? `${this.details.accommodationArrivalTime}h`
        : null;
    },
    arrivalTransportText() {
      return (
        getTransportName(this.details?.arrivalTransport, this.$i18n.locale) ||
        null
      );
    },
    arrivalTransportLocalizatorText() {
      return this.details?.arrivalTransportLocalizator || null;
    },
    arrivalTransportLocalizatorLink() {
      if (
        !this.arrivalTransportLocalizatorText ||
        this.details?.arrivalTransport !== "PLANE"
      )
        return null;
      return `https://www.google.com/search?q=${this.arrivalTransportLocalizatorText}`;
    },
    transportArrivalTimeText() {
      return this.details?.transportArrivalTime
        ? `${this.details.transportArrivalTime}h`
        : null;
    },
    hasArrivalInformation() {
      return (
        this.accommodationArrivalTimeText ||
        this.arrivalTransportText ||
        this.arrivalTransportLocalizatorText ||
        this.transportArrivalTimeText
      );
    },
    accommodationDepartureTimeText() {
      return this.details?.accommodationDepartureTime
        ? `${this.details.accommodationDepartureTime}h`
        : null;
    },
    departureTransportText() {
      return (
        getTransportName(this.details?.departureTransport, this.$i18n.locale) ||
        null
      );
    },
    departureTransportLocalizatorText() {
      return this.details?.departureTransportLocalizator || null;
    },
    departureTransportLocalizatorLink() {
      if (
        !this.departureTransportLocalizatorText ||
        this.details?.departureTransport !== "PLANE"
      )
        return null;
      return `https://www.google.com/search?q=${this.departureTransportLocalizatorText}`;
    },
    transportDepartureTimeText() {
      return this.details?.transportDepartureTime
        ? `${this.details.transportDepartureTime}h`
        : null;
    },
    hasDepartureInformation() {
      return (
        this.accommodationDepartureTimeText ||
        this.departureTransportLocalizatorText ||
        this.transportDepartureTimeText ||
        this.departureTransportText
      );
    },
  },
};
</script>
