<template>
  <div class="guest-report px-5 pt-5">
    <!-- HEADER -->
    <b-row class="text-center mb-5">
      <b-col cols="12">
        <h1>PARTE DE ENTRADA DE VIAJEROS</h1>
        <h2>HOJA REGISTRO</h2>
        <h3>Parte nº: ________</h3>
      </b-col>
    </b-row>

    <!-- DATOS ESTABLECIMIENTO -->
    <b-row class="mb-3">
      <b-col cols="12" class="text-center mb-1">
        <h2>DATOS DEL ESTABLECIMIENTO</h2>
      </b-col>
      <b-col cols="6">
        <div>NIF:</div>
        <div>
          Nombre: <span>{{ accommodation.name }}</span>
        </div>
        <div>Municipio: {{ accommodationCity }}</div>
        <div>Provincia: {{ accommodationRegion }}</div>
      </b-col>
      <b-col cols="6">
        <div class="text-right">Sello establecimiento</div>
      </b-col>
    </b-row>

    <!-- DATOS VIAJERO -->
    <b-row class="mb-3">
      <b-col cols="12" class="text-center mb-1">
        <h2>DATOS DEL VIAJERO</h2>
      </b-col>
      <b-col cols="12">
        <div class="d-flex justify-content-between">
          <span>Nº de documento de identidad:</span>
          <span>{{ guest.idNumber }}</span>
        </div>
        <div class="d-flex justify-content-between">
          <span>Tipo de documento:</span>
          <span>{{ idTypeName(guest.idType) }}</span>
        </div>
        <div class="d-flex justify-content-between">
          <span>Fecha expedición del documento:</span>
          <span>{{ formatDateStringToDate(guest.idIssueDate) }}</span>
        </div>
        <div class="d-flex justify-content-between">
          <span>Primer apellido:</span>
          <span>{{ firstLastName }}</span>
        </div>
        <div class="d-flex justify-content-between">
          <span>Segundo apellido:</span>
          <span>{{ secondLastName }}</span>
        </div>
        <div class="d-flex justify-content-between">
          <span>Nombre:</span>
          <span>{{ guest.firstName }}</span>
        </div>
        <div class="d-flex justify-content-between">
          <span>Sexo:</span>
          <span>{{ genderName(guest.gender) }}</span>
        </div>
        <div class="d-flex justify-content-between">
          <span>Fecha de nacimiento:</span>
          <span>{{ formatDateStringToDate(guest.birthDate) }}</span>
        </div>
        <div class="d-flex justify-content-between">
          <span>País de nacionalidad:</span>
          <span>{{ countryName(guest.nationality) }}</span>
        </div>
        <div class="d-flex justify-content-between">
          <span>Fecha de entrada:</span>
          <span>{{ formatDateStringToDate(booking.checkin) }}</span>
        </div>
      </b-col>
    </b-row>

    <!-- FOOTER -->
    <b-row class="text-center">
      <b-col cols="12">
        En {{ accommodationCity }}, a
        {{ formatDateStringToDate(guest.confirmedDate) }}
      </b-col>
      <b-col cols="12"> Firma del viajero </b-col>
      <b-col cols="12 mb-3">
        <b-img
          v-if="guest.signature"
          class="guest-signature"
          :src="guest.signature"
          alt=""
          fluid
          center
        />
      </b-col>
      <b-col cols="12" class="lopd text-left">
        La recogida de datos se hará de acuerdo con la ley Orgánica 15/1999, de
        13 de diciembre, de Protección de Datos de Carácter Personal y al amparo
        de lo dispuesto en el artículo 12.1 de la ley Orgánica 4/2015, de 30 de
        marzo, de protección de la seguridad ciudadana.
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol, BImg } from "bootstrap-vue";
import { formatDateStringToDate } from "@formatters";
import { getCountryName, getGenderName, getIdTypeName } from "@methods";

export default {
  components: {
    BRow,
    BCol,
    BImg,
  },
  props: {
    accommodation: {
      type: Object,
      default: null,
    },
    booking: {
      type: Object,
      default: null,
    },
    guest: {
      type: Object,
      default: null,
    },
  },
  computed: {
    firstLastName() {
      if (!this.guest.lastName) return null;
      const splittedLastName = this.guest.lastName.split(" ");
      if (splittedLastName.length < 1) return null;
      return splittedLastName[0];
    },
    secondLastName() {
      if (!this.guest.lastName) return null;
      const splittedLastName = this.guest.lastName.split(" ");
      if (splittedLastName.length <= 1) return null;
      return this.guest.lastName.substr(this.guest.lastName.indexOf(" ") + 1);
    },
    today() {
      return new Date();
    },
    accommodationCity() {
      if (!this.accommodation?.location) return null;
      return this.accommodation.location.city;
    },
    accommodationRegion() {
      if (!this.accommodation?.location) return null;
      return this.accommodation.location.region;
    },
  },
  methods: {
    formatDateStringToDate(date) {
      return formatDateStringToDate(date);
    },
    countryName(code) {
      return getCountryName(code, "es");
    },
    genderName(code) {
      return getGenderName(code, "es");
    },
    idTypeName(code) {
      return getIdTypeName(code, "es");
    },
  },
};
</script>

<style lang="scss">
.guest-report {
  h1 {
    font-size: 20px;
  }
  h2 {
    font-size: 16px;
  }
  h3 {
    font-size: 14px;
  }
  .guest-signature {
    max-width: 250px;
  }
  .lopd {
    font-size: 10px;
  }
}
</style>
