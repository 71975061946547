<template>
  <b-col
    cols="12"
    md="6"
    lg="3"
    class="mb-3 border p-4 bg-light"
    :class="{ 'd-flex justify-content-between': !!link }"
  >
    <div>
      <h6 v-if="label" class="mb-1">
        {{ label }}
      </h6>

      <b-card-text v-if="text">
        {{ text }}
      </b-card-text>
    </div>
    <b-button v-if="link" variant="link" @click="onClick">
      {{ linkText }}
    </b-button>
  </b-col>
</template>

<script>
import { BCol, BCardText, BButton } from "bootstrap-vue";

export default {
  components: {
    BCol,
    BCardText,
    BButton,
  },
  props: {
    label: {
      type: String,
      default: null,
    },
    text: {
      type: String,
      default: null,
    },
    link: {
      type: String,
      default: null,
    },
    buttonText: {
      type: String,
      default: null,
    },
  },
  computed: {
    linkText() {
      return this.buttonText || this.$t("See details");
    },
  },
  methods: {
    onClick() {
      if (!this.link) return;
      window.open(this.link, "_blank");
    },
  },
};
</script>
