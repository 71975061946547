<template>
  <b-overlay :show="loading" variant="white" :opacity="1">
    <b-card :title="$t('Security deposit')">
      <b-alert
        v-if="hasChargableSecurityDeposit || hasManagedSecurityDeposit"
        variant="success"
        :show="true"
        class="mb-0"
      >
        <div class="alert-body font-weight-normal">
          {{ $t("pages.booking.payments.agencyManagedSecurityDeposit") }}
        </div>
      </b-alert>
      <template v-else>
        <b-alert
          v-if="hasSecurityDeposit && !hasChargableSecurityDeposit"
          variant="warning"
          :show="true"
          class="mb-0"
        >
          <div class="alert-body font-weight-normal">
            {{ $t("pages.booking.payments.ownerManagedSecurityDeposit") }}
          </div>
        </b-alert>
        <b-alert v-else variant="danger" :show="true" class="mb-0">
          <div class="alert-body font-weight-normal">
            {{ $t("pages.booking.payments.noSecurityDeposit") }}
          </div>
        </b-alert>
      </template>
    </b-card>
  </b-overlay>
</template>

<script>
import { BOverlay, BCard, BAlert } from "bootstrap-vue";

export default {
  components: {
    BOverlay,
    BCard,
    BAlert,
  },
  computed: {
    loading() {
      return this.$store.getters["booking/loading"];
    },
    hasSecurityDeposit() {
      return this.$store.getters["booking/hasSecurityDeposit"];
    },
    hasChargableSecurityDeposit() {
      return this.$store.getters["booking/hasChargableSecurityDeposit"];
    },
    hasManagedSecurityDeposit() {
      return this.$store.getters["booking/hasManagedSecurityDeposit"];
    },
  },
};
</script>
