<template>
  <b-overlay :show="loading" variant="white" :opacity="1">
    <b-card :title="$t('pages.booking.payments.onSiteCostsTitle')">
      <b-row>
        <b-col v-if="ownerUnchargableServices.length" cols="12">
          <b-row class="no-gutters">
            <gray-box-item
              v-for="bookingService in ownerUnchargableServices"
              :key="`unchargable-service-${bookingService.uuid}`"
              :label="getBookingServiceName(bookingService, $i18n.locale)"
              :text="
                getBookingServiceOwnerUnitPriceText(
                  bookingService,
                  $i18n.locale
                )
              "
            />
          </b-row>
        </b-col>
        <b-col v-else>
          <b-alert variant="success" :show="true" class="mb-0">
            <div class="alert-body font-weight-normal">
              {{ $t("pages.booking.payments.noOnsiteCosts") }}
            </div>
          </b-alert>
        </b-col>
      </b-row>
    </b-card>
  </b-overlay>
</template>

<script>
import { BCard, BOverlay, BRow, BCol, BAlert } from "bootstrap-vue";
import GrayBoxItem from "@/views/bookings/booking/components/GrayBoxItem.vue";
import {
  getBookingServiceName,
  getBookingServiceOwnerUnitPriceText,
} from "@methods";

export default {
  components: {
    BCard,
    BOverlay,
    BRow,
    BCol,
    GrayBoxItem,
    BAlert,
  },
  data() {
    return {
      getBookingServiceName,
      getBookingServiceOwnerUnitPriceText,
    };
  },
  computed: {
    loading() {
      return this.$store.getters["booking/loading"];
    },
    ownerUnchargableServices() {
      return this.$store.getters["booking/ownerUnchargableServices"];
    },
  },
};
</script>
