<template>
  <div v-if="booking" id="booking-view">
    <vue-horizontal class="mb-3 border-bottom">
      <template v-slot:btn-prev>
        <feather-icon
          size="32"
          height="100"
          class="btn-left"
          icon="ChevronLeftIcon"
        />
      </template>
      <template v-slot:btn-next>
        <feather-icon
          size="32"
          height="100"
          class="btn-right"
          icon="ChevronRightIcon"
        />
      </template>
      <div @click="tabIndex = 0" :class="getTabClass(tabIndex, 0)">
        <feather-icon class="mr-1" icon="InfoIcon" />
        <span>
          {{ $t("Booking") }}
        </span>
      </div>
      <div @click="tabIndex = 1" :class="getTabClass(tabIndex, 1)">
        <feather-icon class="mr-1" icon="DollarSignIcon" />
        <span>
          {{ $t("Payments") }}
        </span>
      </div>
      <div @click="tabIndex = 2" :class="getTabClass(tabIndex, 2)">
        <feather-icon class="mr-1" icon="UsersIcon" />
        <span>
          {{ $t("Guests") }}
        </span>
      </div>
      <div @click="tabIndex = 3" :class="getTabClass(tabIndex, 3)">
        <feather-icon class="mr-1" icon="AwardIcon" />
        <span>
          {{ $t("Review") }}
        </span>
      </div>
    </vue-horizontal>
    <b-tabs no-fade v-model="tabIndex" nav-wrapper-class="d-none">
      <!-- BOOKING TAB -->
      <b-tab active>
        <b-row>
          <b-col cols="12">
            <summary-card />
          </b-col>
          <b-col cols="12">
            <requests-card />
          </b-col>
          <b-col cols="12">
            <schedules-card />
          </b-col>
        </b-row>
      </b-tab>

      <!-- PAYMENTS TAB -->
      <b-tab>
        <b-row>
          <b-col v-if="!contractHasFixedSeasonPrice" cols="12">
            <owner-accounting-summary-card />
          </b-col>
          <b-col v-if="ownerUnchargableServices.length" cols="12">
            <owner-accounting-on-site-costs-card />
          </b-col>
          <b-col cols="12">
            <security-deposit-card />
          </b-col>
        </b-row>
      </b-tab>

      <!-- GUESTS TAB -->
      <b-tab>
        <b-row>
          <b-col cols="12">
            <guests-card />
          </b-col>
        </b-row>
      </b-tab>

      <!-- REVIEW TAB -->
      <b-tab>
        <b-row>
          <b-col cols="12">
            <review-card />
          </b-col>
        </b-row>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import { BRow, BCol, BTabs, BTab } from "bootstrap-vue";
import SummaryCard from "@/views/bookings/booking/components/SummaryCard.vue";
import SchedulesCard from "@/views/bookings/booking/components/SchedulesCard.vue";
import RequestsCard from "@/views/bookings/booking/components/RequestsCard.vue";
import OwnerAccountingSummaryCard from "@/views/bookings/booking/components/OwnerAccountingSummaryCard.vue";
import OwnerAccountingOnSiteCostsCard from "@/views/bookings/booking/components/OwnerAccountingOnSiteCostsCard.vue";
import SecurityDepositCard from "@/views/bookings/booking/components/SecurityDepositCard.vue";
import GuestsCard from "@/views/bookings/booking/components/GuestsCard.vue";
import ReviewCard from "@/views/bookings/booking/components/ReviewCard.vue";
import { notifyError } from "@methods";
import VueHorizontal from "vue-horizontal";

export default {
  components: {
    BTabs,
    BTab,
    BRow,
    BCol,
    SummaryCard,
    SchedulesCard,
    RequestsCard,
    OwnerAccountingSummaryCard,
    OwnerAccountingOnSiteCostsCard,
    SecurityDepositCard,
    GuestsCard,
    ReviewCard,
    VueHorizontal,
  },
  data() {
    return {
      tabIndex: 0,
    };
  },
  computed: {
    loading() {
      return this.$store.getters["booking/loading"];
    },
    booking() {
      return this.$store.getters["booking/booking"];
    },
    contract() {
      return this.$store.getters["booking/contract"];
    },
    contractHasFixedSeasonPrice() {
      return this.contract?.fixedSeasonPrice || false;
    },
    loadingServices() {
      return this.$store.getters["services/loadingServices"];
    },
    services() {
      return this.$store.getters["services/services"];
    },
    loadingProviders() {
      return this.$store.getters["providers/loading"];
    },
    providers() {
      return this.$store.getters["providers/providers"];
    },
    loadingPolicies() {
      return this.$store.getters["bookingPolicies/loadingPolicies"];
    },
    policies() {
      return this.$store.getters["bookingPolicies/policies"];
    },
    ownerUnchargableServices() {
      return this.$store.getters["booking/ownerUnchargableServices"];
    },
  },
  watch: {
    booking(booking) {
      this.setPageTitle(booking);
    },
  },
  mounted() {
    if (!this.loading) this.fetchBooking();
    if (!this.services?.length && !this.loadingServices) this.fetchServices();
    if (!this.providers?.length && !this.loadingProviders)
      this.fetchProviders();
    if (!this.policies?.length && !this.loadingPolicies) this.fetchPolicies();
    this.setPageTitle(this.booking);
  },
  beforeDestroy() {
    this.$store.dispatch("booking/reset");
    this.setPageTitle(null);
  },
  methods: {
    getTabClass(selectedIndex, index) {
      return ` font-weight-bold pb-3 cursor-pointer  px-3 ${
        selectedIndex === index ? "btn-active" : ""
      }`;
    },
    fetchBooking() {
      this.$store.dispatch("app/setLoading", true);
      const { localizator } = this.$route.params;

      this.$store
        .dispatch("booking/fetchBookingByLocalizator", localizator)
        .catch(() => {
          notifyError(
            this.$t("errors.fetchBooking.title"),
            this.$t("errors.fetchBooking.description")
          );
        })
        .finally(() => this.$store.dispatch("app/setLoading", false));
    },
    fetchServices() {
      this.$store
        .dispatch("services/fetchServices", {
          pagination: false,
          sort: "asc",
        })
        .catch(() =>
          notifyError(
            "Error",
            "Hi ha hagut un error al intenter carregar els serveis"
          )
        );
    },
    fetchProviders() {
      this.$store
        .dispatch("providers/fetchProviders", {
          pagination: false,
          sort: "asc",
        })
        .catch(() =>
          notifyError(
            "Error",
            "Hi ha hagut un error al intenter carregar els proveïdors"
          )
        );
    },
    fetchPolicies() {
      this.$store
        .dispatch("bookingPolicies/fetchPolicies", {
          pagination: false,
          sort: "asc",
        })
        .catch(() =>
          notifyError(
            this.$t("errors.fetchPolicies.title"),
            this.$t("errors.fetchPolicies.description")
          )
        );
    },
    setPageTitle(booking) {
      this.$store.dispatch("app/setPageTitle", booking?.localizator || null);
      this.$store.dispatch(
        "app/setPageSubtitle",
        booking?.accommodation?.name || null
      );
    },
  },
};
</script>

<style lang="scss">
#booking-view {
  min-height: 100px;
  // Posam aquest min height perquè s'spinner mentre carrega informació quedi visible
  .btn-left,
  .btn-right {
    padding: 6px;
    height: 100%;
  }
  .btn-left {
    background: linear-gradient(to left, #ffffff00 0, #fff 50%, #fff);
  }
  .btn-right {
    background: linear-gradient(to right, #ffffff00 0, #fff 50%, #fff);
  }
  .btn-active {
    color: #ffcd00;
    border-bottom: 2px solid;
  }
}
</style>
