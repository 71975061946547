<template>
  <div class="b-divider">
    <div
      class="border-bottom border-top-0 border-left-0 border-right-0"
      :class="{
        'border-style-dashed': dashed,
        'my-0': size === 'none',
        'my-1': size === 'sm',
        'my-2': size === 'md',
        'my-3': size === 'lg',
        'my-4': size === 'xl',
      }"
    />
  </div>
</template>

<script>
export default {
  props: {
    dashed: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: "none",
    },
  },
};
</script>
