<template>
  <b-overlay :show="loading" rounded="sm" variant="white" :opacity="1">
    <b-card>
      <!-- GUESTS -->
      <b-row class="mb-3">
        <b-col cols="12">
          <h5>
            {{ $t("Adults") }}
            <br />
            <small>{{ $t("pages.booking.adultsAgeDefinition") }}</small>
          </h5>
        </b-col>
        <b-col cols="12">
          <b-list-group v-if="guests.length > 0" flush>
            <b-list-group-item
              v-for="guest in guests"
              :key="`guest-${guest.uuid}`"
              class="d-sm-flex justify-content-sm-between align-items-center pl-0"
            >
              <guest-item :guest="guest" />
            </b-list-group-item>
          </b-list-group>
          <b-alert variant="warning" class="mb-2" :show="guests.length === 0">
            <div class="alert-body">
              {{ $t("pages.booking.guests.noGuests") }}
            </div>
          </b-alert>
        </b-col>
      </b-row>

      <!-- CHILDREN AND BABIES -->
      <b-row class="mb-4">
        <!-- CHILDREN -->
        <b-col cols="12" class="mb-3">
          <b-row>
            <b-col cols="12">
              <h5>
                {{ $t("Children") }}
                <br />
                <small>{{ $t("pages.booking.childrenAgeDefinition") }}</small>
              </h5>
              <b-badge v-if="hasSpecifiedChildren">
                {{ children }}
              </b-badge>
              <b-alert v-else variant="warning" :show="true" class="mt-3">
                <div class="alert-body font-weight-normal">
                  {{ $t("pages.booking.guests.noChildrenInformation") }}
                </div>
              </b-alert>
            </b-col>
          </b-row>
        </b-col>
        <!-- BABIES -->
        <b-col cols="12">
          <b-row>
            <b-col cols="12">
              <h5>
                {{ $t("Babies") }}
                <br />
                <small>{{ $t("pages.booking.babiesAgeDefinition") }}</small>
              </h5>
              <b-badge v-if="hasSpecifiedBabies">
                {{ babies }}
              </b-badge>
              <b-alert v-else variant="warning" :show="true" class="mt-3">
                <div class="alert-body font-weight-normal">
                  {{ $t("pages.booking.guests.noBabiesInformation") }}
                </div>
              </b-alert>
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <!-- BUTTONS -->
      <b-row class="d-flex justify-content-end">
        <b-col cols="12" sm="auto">
          <b-button
            block
            variant="primary"
            :disabled="!guests.length"
            @click="generateReport"
          >
            <feather-icon icon="DownloadIcon" class="mr-1" />
            {{ $t("Guests report") }}
          </b-button>
        </b-col>
      </b-row>
    </b-card>

    <!-- GUESTS PDF -->
    <vue-html2pdf
      v-if="guests && accommodation"
      ref="html2Pdf"
      :show-layout="false"
      :enable-download="true"
      :preview-modal="false"
      :pdf-quality="1"
      :manual-pagination="true"
      :filename="guestsReportFilename"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="794px"
      @progress="onProgress($event)"
      @hasDownloaded="onGuestsReportDownloaded($event)"
    >
      <section slot="pdf-content">
        <section
          v-for="guest in guests"
          :key="`guest-${guest.uuid}`"
          class="pdf-item"
        >
          <guest-report
            :guest="guest"
            :accommodation="accommodation"
            :booking="booking"
          />
          <div class="html2pdf__page-break" />
        </section>
      </section>
    </vue-html2pdf>
  </b-overlay>
</template>

<script>
import {
  BOverlay,
  BCard,
  BRow,
  BCol,
  BListGroup,
  BListGroupItem,
  BAlert,
  BBadge,
  BButton,
} from "bootstrap-vue";
import VueHtml2pdf from "vue-html2pdf";
import GuestReport from "@/views/bookings/booking/components/GuestReport.vue";
import GuestItem from "@/views/bookings/booking/components/GuestItem.vue";

export default {
  name: "GuestsCard",
  components: {
    BOverlay,
    BCard,
    BRow,
    BCol,
    BListGroup,
    BListGroupItem,
    BAlert,
    BBadge,
    BButton,
    GuestItem,
    VueHtml2pdf,
    GuestReport,
  },
  computed: {
    loading() {
      return this.$store.getters["booking/loading"];
    },
    booking() {
      return this.$store.getters["booking/booking"];
    },
    accommodation() {
      return this.$store.getters["booking/accommodation"];
    },
    guests() {
      return this.$store.getters["booking/confirmedGuests"];
    },
    children() {
      return this.booking?.children;
    },
    hasSpecifiedChildren() {
      return this.children !== null;
    },
    babies() {
      return this.booking?.babies;
    },
    hasSpecifiedBabies() {
      return this.babies !== null;
    },
    guestsReportFilename() {
      const name = [this.$t("pages.booking.guests.guestsReportFilename")];
      if (this.booking?.localizator) name.push(this.booking.localizator);
      return name.join(" ");
    },
  },
  methods: {
    generateReport() {
      this.$store.dispatch("app/setLoading", true);
      setTimeout(() => {
        this.$refs.html2Pdf.generatePdf();
      }, 100);
    },
    onProgress(percentage) {
      if (percentage === 100) this.$store.dispatch("app/setLoading", false);
    },
    onGuestsReportDownloaded() {
      this.$store.dispatch("app/setLoading", false);
    },
  },
};
</script>
