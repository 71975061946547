<template>
  <div class="guest-item">
    <div class="d-flex align-items-center">
      <b-avatar class="mr-3" />
      <div>
        <h6 class="mb-1">
          {{ guest.fullName || "Adult" }}
        </h6>
        <small>{{ guestSubtitle(guest) }}</small>
      </div>
    </div>
  </div>
</template>

<script>
import { getCountryName, getGenderName } from "@methods";
import { BAvatar } from "bootstrap-vue";

export default {
  components: {
    BAvatar,
  },
  props: {
    guest: {
      type: Object,
      required: true,
    },
  },
  methods: {
    guestSubtitle(guest) {
      if (!guest) return null;
      const text = [];
      if (guest.gender) {
        const genderName = getGenderName(guest.gender, this.$i18n.locale);
        if (genderName) text.push(genderName);
      }
      if (guest.age) {
        text.push(`${guest.age} anys`);
      }
      if (guest.nationality) {
        text.push(getCountryName(guest.nationality, this.$i18n.locale));
      }

      return text.length > 0 ? text.join(", ") : null;
    },
  },
};
</script>

<style lang="scss" scoped>
.badge-text {
  font-size: 0.7rem;
}
</style>
