<template>
  <b-col cols="12" sm="6" md="4" lg="3" class="my-2 my-sm-3">
    <div class="d-flex align-items-top">
      <feather-icon v-if="icon" :icon="icon" size="20" class="mr-3" />
      <div>
        <h6 class="mb-1">
          {{ label }}
        </h6>
        <b-card-text>
          {{ text }}
        </b-card-text>
      </div>
    </div>
  </b-col>
</template>

<script>
import { BCol, BCardText } from "bootstrap-vue";

export default {
  components: {
    BCol,
    BCardText,
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      default: null,
    },
    icon: {
      type: String,
      default: null,
    },
  },
};
</script>
