<template>
  <b-overlay :show="loading" variant="white" :opacity="1">
    <b-card :title="$t('Requests')">
      <b-row>
        <b-col cols="12">
          <b-row>
            <b-col cols="12" class="mb-3">
              <h6 class="mb-1">
                {{ $t("Baby cots") }}
              </h6>
              <b-badge v-if="babyCotsRequested">
                {{ babyCotsText }}
              </b-badge>
              <b-alert v-else variant="warning" :show="true" class="mt-3">
                <div class="alert-body font-weight-normal">
                  {{ $t("pages.booking.requests.noBabyCotsInformation") }}
                </div>
              </b-alert>
            </b-col>
            <b-col cols="12">
              <h6 class="mb-1">
                {{ $t("Baby chairs") }}
              </h6>
              <b-badge v-if="babyChairsRequested">
                {{ babyChairsText }}
              </b-badge>
              <b-alert v-else variant="warning" :show="true" class="mt-3">
                <div class="alert-body font-weight-normal">
                  {{ $t("pages.booking.requests.noBabyChairsInformation") }}
                </div>
              </b-alert>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-card>
  </b-overlay>
</template>

<script>
import { BCard, BRow, BCol, BOverlay, BAlert, BBadge } from "bootstrap-vue";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BOverlay,
    BAlert,
    BBadge,
  },
  computed: {
    loading() {
      return this.$store.getters["booking/loading"];
    },
    details() {
      return this.$store.getters["booking/details"];
    },
    babyCots() {
      return this.details?.babyCots;
    },
    babyChairs() {
      return this.details?.babyChairs;
    },
    babyCotsRequested() {
      return typeof this.babyCots === "number";
    },
    babyChairsRequested() {
      return typeof this.babyChairs === "number";
    },
    babyCotsText() {
      if (!this.details?.babyCots && this.details?.babyCots !== 0)
        return this.$t("Not defined");
      return this.details.babyCots;
    },
    babyChairsText() {
      if (!this.details?.babyChairs && this.details?.babyChairs !== 0)
        return this.$t("Not defined");
      return this.details.babyChairs;
    },
  },
};
</script>
