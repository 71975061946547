<template>
  <b-card>
    <b-overlay :show="loadingBooking" variant="white" :opacity="1">
      <!-- BOOKING STATUS -->
      <b-row class="mb-4">
        <b-col cols="12">
          <!-- STATUS -->
          <b-badge :variant="bookingStatusColor">
            {{ bookingStatusName }}
          </b-badge>
        </b-col>
      </b-row>

      <!-- BOOKING SUMMARY -->
      <b-row>
        <summary-item
          :label="$t('Booking nº')"
          :text="bookingLocalizator"
          icon="HashIcon"
        />
        <summary-item
          :label="$t('Booking date')"
          :text="bookingDateTime"
          icon="ClockIcon"
        />
        <summary-item
          :label="$t('Check-in')"
          :text="bookingCheckin"
          icon="LogInIcon"
        />
        <summary-item
          :label="$t('Check out')"
          :text="bookingCheckout"
          icon="LogOutIcon"
        />
        <summary-item
          v-if="showAccommodationName"
          :label="$t('Accommodation')"
          :text="accommodationName"
          icon="HomeIcon"
        />
        <summary-item
          :label="$t('Client')"
          :text="clientName"
          icon="UserIcon"
        />
        <summary-item
          :label="$t('Guests')"
          :text="bookingGuests"
          icon="UsersIcon"
        />
        <summary-item
          :label="$t('Nights')"
          :text="bookingNights"
          icon="MoonIcon"
        />
      </b-row>
    </b-overlay>
  </b-card>
</template>

<script>
import { BCard, BRow, BCol, BBadge, BOverlay } from "bootstrap-vue";
import { formatDateStringToDate } from "@formatters";
import {
  getBookingStatusColor,
  getBookingStatusName,
  getGuestsText,
  getNightsText,
} from "@methods";
import SummaryItem from "@/views/bookings/booking/components/SummaryItem.vue";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BBadge,
    BOverlay,
    SummaryItem,
  },
  computed: {
    showAccommodationName() {
      return this.$store.getters["ui/showAccommodationName"];
    },
    loadingBooking() {
      return this.$store.getters["booking/loading"];
    },
    booking() {
      return this.$store.getters["booking/booking"];
    },
    bookingDateTime() {
      const formatting = {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      };
      return (
        formatDateStringToDate(
          this.booking?.date,
          this.$i18n.locale,
          formatting
        ) || this.$t("Not defined")
      );
    },
    bookingStatusName() {
      return (
        getBookingStatusName(this.booking.status, this.$i18n.locale) ||
        this.$t("Not defined")
      );
    },
    bookingStatusColor() {
      return getBookingStatusColor(this.booking.status);
    },
    accommodationName() {
      return this.booking?.accommodation?.name || this.$t("Not defined");
    },
    clientName() {
      return this.booking?.client?.fullName || this.$t("Not defined");
    },
    bookingCheckin() {
      return (
        formatDateStringToDate(this.booking.checkin) || this.$t("Not defined")
      );
    },
    bookingCheckout() {
      return (
        formatDateStringToDate(this.booking.checkout) || this.$t("Not defined")
      );
    },
    bookingLocalizator() {
      return this.booking?.localizator || this.$t("Not defined");
    },
    bookingNights() {
      return (
        getNightsText(this.booking.nights, this.$i18n.locale) ||
        this.$t("Not defined")
      );
    },
    bookingGuests() {
      return (
        getGuestsText(
          {
            adults: this.booking.adults,
            children: this.booking.children,
            babies: this.booking.babies,
          },
          this.$i18n.locale
        ) || this.$t("Not defined")
      );
    },
  },
};
</script>
