<template>
  <b-overlay :show="loading" variant="white" :opacity="1">
    <b-card :title="$t('pages.booking.priceDetails')">
      <!-- RATES SUMMARY -->
      <div
        v-for="rate in ownerRates"
        :key="`booking-rate-${rate.uuid}`"
        class="d-flex justify-content-between mb-2"
      >
        <span class="text-grey d-flex align-items-center">
          {{ formatOwnerRateDate(rate.date) }}
        </span>
        <span>{{ formatCurrency(rate.price) }}</span>
      </div>

      <b-divider class="my-3" />

      <template v-if="ownerChargableServices.length">
        <!-- SUBTOTAL -->
        <div class="d-flex justify-content-between font-weight-bold mb-2">
          <span>{{ $t("pages.booking.subtotal") }}</span>
          <span>{{ formatCurrency(ownerSubtotal) }}</span>
        </div>

        <!-- CHARGABLE SERVICES -->
        <div
          v-for="bookingService in ownerChargableServices"
          :key="`chargable-service-${bookingService.uuid}`"
          class="d-flex justify-content-between mb-2"
        >
          <span class="text-grey">
            {{ serviceName(bookingService) }}
          </span>
          <span>{{ bookingServiceCostPriceText(bookingService) }}</span>
        </div>

        <b-divider class="my-3" />
      </template>

      <!-- TOTAL -->
      <div class="d-flex justify-content-between font-weight-bold mb-2">
        <span>{{ $t("pages.booking.total") }}</span>
        <span>{{ formatCurrency(ownerTotal) }}</span>
      </div>
    </b-card>
  </b-overlay>
</template>

<script>
import { BCard, BOverlay } from "bootstrap-vue";
import { formatCurrency, formatDateStringToDate } from "@formatters";
import BDivider from "@foravila-core/components/b-divider/BDivider.vue";
import {
  getBookingServiceName,
  getBookingServiceOwnerTotalPriceText,
} from "@methods";

export default {
  components: {
    BDivider,
    BCard,
    BOverlay,
  },
  computed: {
    booking() {
      return this.$store.getters["booking/booking"];
    },
    loading() {
      return this.$store.getters["booking/loading"];
    },
    ownerRates() {
      return this.$store.getters["booking/ownerRates"];
    },
    ownerSubtotal() {
      return this.$store.getters["booking/ownerSubtotal"];
    },
    ownerTotal() {
      return this.$store.getters["booking/ownerTotal"];
    },
    ownerChargableServices() {
      return this.$store.getters["booking/ownerChargableServices"];
    },
  },
  methods: {
    formatOwnerRateDate(date) {
      const formatting = { day: "numeric", month: "long", year: "numeric" };
      return (
        formatDateStringToDate(date, this.$i18n.locale, formatting) ||
        this.$t("Not available")
      );
    },
    formatCurrency(price) {
      return formatCurrency(price) || this.$t("Not available");
    },
    bookingServiceCostPriceText(bookingService) {
      return getBookingServiceOwnerTotalPriceText(
        bookingService,
        this.booking.nights,
        this.$i18n.locale
      );
    },
    serviceName(bookingService) {
      return getBookingServiceName(bookingService, this.$i18n.locale);
    },
  },
};
</script>
