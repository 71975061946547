<template>
  <div>
    <b-card v-if="review">
      <review-card :review="review" />
    </b-card>
    <b-card v-else>
      <div
        class="d-flex flex-column justify-content-center align-items-center py-5"
      >
        <feather-icon icon="AwardIcon" size="25" class="mr-2 mb-3" />
        <h5>
          {{ $t("pages.booking.noReviewYet") }}
        </h5>
      </div>
    </b-card>
  </div>
</template>

<script>
import { BCard } from "bootstrap-vue";
import ReviewCard from "@/views/reviews/list/components/ReviewCard.vue";

export default {
  components: {
    BCard,
    ReviewCard,
  },
  computed: {
    review() {
      return this.$store.getters["booking/review"];
    },
  },
};
</script>

<style></style>
